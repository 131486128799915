import React from "react";
import styled from "styled-components/macro";
import { Field, useFormikContext, FieldProps } from "formik";
import Row from "../../layout/Row";
import Col from "../../layout/Col";
import RadioField from "./RadioField";
import SelectField from "./SelectField";
import FormGroup from "../FormGroup";
import Label from "../Label";

export const Ideal = () => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 32 24"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m4.12903.5h23.74197c1.9991 0 3.629 1.63976 3.629 3.67391v15.65219c0 2.0341-1.6299 3.6739-3.629 3.6739h-23.74197c-1.99917 0-3.62903-1.6398-3.62903-3.6739v-15.65219c0-2.03415 1.62986-3.67391 3.62903-3.67391z"
      fill="#fff"
      stroke="#e6e6e6"
    />
    <g clipRule="evenodd" fillRule="evenodd">
      <path
        d="m0 0v12.5212h8.30034c1.81306-.027 3.24946-.475 4.26866-1.3376 1.2396-1.0491 1.8682-2.70543 1.8682-4.92302 0-1.06004-.1653-2.01089-.4914-2.82608-.3119-.77984-.7728-1.4429-1.37-1.97079-1.0517-.929695-2.5279-1.432394-4.27546-1.46095396 0-.00001584-2.76678-.00093441-8.30034-.00275604z"
        fill="#fff"
        transform="translate(8.77417 5.73972)"
      />
      <path
        d="m0 3.82108h2.31348v-3.82108h-2.31348z"
        fill="#0a0b09"
        transform="translate(10.3789 12.7876)"
      />
      <path
        d="m2.61911 1.30262c0 .71937-.58635 1.30288-1.30956 1.30288-.723173 0-1.30955-.58351-1.30955-1.30288 0-.719373.586377-1.30262 1.30955-1.30262.72321 0 1.30956.583247 1.30956 1.30262z"
        fill="#0a0b09"
        transform="translate(10.2479 9.57452)"
      />
      <path
        d="m8.14328.831615c1.60864 0 2.94932.433535 3.87722 1.253745 1.0488.92718 1.5806 2.33191 1.5806 4.17522 0 3.65312-1.7852 5.42902-5.45782 5.42902-.28542 0-6.57329 0-7.307236 0 0-.745 0-10.11302 0-10.857985zm.15706-.831615h-8.30034v12.5212h8.30034v-.0024c1.81306-.0246 3.24946-.4726 4.26866-1.3352 1.2396-1.0491 1.8682-2.70543 1.8682-4.92302 0-1.06004-.1653-2.01089-.4914-2.82608-.3119-.77984-.7728-1.4429-1.37-1.97079-1.0517-.929695-2.5279-1.432394-4.27546-1.46095396 0-.00002376 0-.00275604 0-.00275604z"
        fill="#0a0b09"
        transform="translate(8.77417 5.73972)"
      />
      <path
        d="m8.14328.831615c1.60864 0 2.94932.433535 3.87722 1.253745 1.0488.92718 1.5806 2.33191 1.5806 4.17522 0 3.65312-1.7852 5.42902-5.45782 5.42902-.28542 0-6.57329 0-7.307236 0 0-.745 0-10.11302 0-10.857985zm.15706-.831615h-8.30034v12.5212h8.30034v-.0024c1.81306-.0246 3.24946-.4726 4.26866-1.3352 1.2396-1.0491 1.8682-2.70543 1.8682-4.92302 0-1.06004-.1653-2.01089-.4914-2.82608-.3119-.77984-.7728-1.4429-1.37-1.97079-1.0517-.929695-2.5279-1.432394-4.27546-1.46095396 0-.00002376 0-.00275604 0-.00275604z"
        fill="#0a0b09"
        transform="translate(8.77417 5.73972)"
      />
      <path
        d="m2.93449 9.02922h-2.93449v-9.02922h2.93449-.11843c2.44726 0 5.05221.960705 5.05221 4.52648 0 3.76966-2.60495 4.50274-5.05221 4.50274z"
        fill="#cd0067"
        transform="translate(13.7546 7.58371)"
      />
      <path
        d="m.440403.368287v1.436013h.329951c.1234 0 .212475-.0069.267226-.0207.07166-.01775.13111-.04783.17841-.09022.04725-.04239.08585-.11215.11571-.20926s.04479-.22947.04479-.39708c0-.167607-.01493-.29627-.04479-.386013-.02986-.089695-.07166-.159693-.12541-.20997-.05374-.050301-.12194-.084302-.20454-.102075-.061727-.013781-.182642-.020695-.362774-.020695zm-.440403-.368287h.805683c.181708 0 .320207.0138291.415537.0414863.12806.0375414.23777.1042367.32912.2000867.09134.095826.16083.213154.2085.351962.04766.138809.0715.309979.0715.513485 0 .17885-.02236.33296-.06703.46238-.0546.1581-.13255.28603-.2338.38385-.07647.07411-.17973.13189-.30979.17338-.09732.03065-.227361.04595-.390198.04595h-.829522z"
        fill="#fffffe"
        transform="translate(13.2489 9.91452)"
      />
      <path
        d="m0 2.17258v-2.17258h1.61686v.368287h-1.176432v.481148h1.094312v.368285h-1.094312v.58658h1.218232v.36828z"
        fill="#fffffe"
        transform="translate(15.5775 9.91452)"
      />
      <path
        d="m1.37974 1.31127-.30108-.804888-.295024.804888zm.81189.86131h-.47977l-.19059-.49303h-.872596l-.180777.49303h-.467897l.848777-2.17258h.469403z"
        fill="#fffffe"
        transform="translate(17.5109 9.91452)"
      />
      <path
        d="m0 2.15476v-2.15476h.440428v1.78647h1.097292v.36829z"
        fill="#fffffe"
        transform="translate(20.0709 9.93234)"
      />
    </g>
  </svg>
);
export const Bancontact = () => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 32 24"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m4.096.5h23.808c1.9822 0 3.596 1.62098 3.596 3.62903v15.74197c0 2.008-1.6138 3.629-3.596 3.629h-23.808c-1.98224 0-3.596-1.621-3.596-3.629v-15.74197c0-2.00805 1.61376-3.62903 3.596-3.62903z"
      fill="#fff"
      stroke="#e6e6e6"
    />
    <g clipRule="evenodd" fillRule="evenodd">
      <path
        d="m0 2.85246c0-.52288.419071-.94675.93461-.94675h2.84631l-1.41784 1.42928h5.67138l2.83574-3.33499h6.144l-4.0986 4.54477c-.3466.38435-1.0364.69592-1.5512.69592h-10.440803c-.510088 0-.923597-.42237-.923597-.94675z"
        fill="#00549d"
        transform="translate(6.07507 10.5111)"
      />
      <path
        d="m0 2.84785c0-.52033.423396-.94214.922955-.94214h3.330585l-1.41785 1.42928h6.144l2.36311-3.33499h6.144l-4.1276 4.99304c-.3306.3999-1.0232.72408-1.5369.72408h-10.883981c-.518219 0-.938319-.42245-.938319-.94215z"
        fill="#ffd800"
        transform="matrix(-1 0 0 -1 25.8657 13.9056)"
      />
    </g>
  </svg>
);
export const Mastercard = () => (
  <svg
    width="32"
    height="24"
    viewBox="0 0 32 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.12903 0.5H27.871C29.8701 0.5 31.5 2.13976 31.5 4.17391V19.8261C31.5 21.8602 29.8701 23.5 27.871 23.5H4.12903C2.12986 23.5 0.5 21.8602 0.5 19.8261V4.17391C0.5 2.13976 2.12986 0.5 4.12903 0.5Z"
      fill="white"
      stroke="#E6E6E6"
    />
    <path
      d="M5.16129 10.4348C8.01179 10.4348 10.3226 8.09888 10.3226 5.21739C10.3226 2.33591 8.01179 0 5.16129 0C2.31079 0 0 2.33591 0 5.21739C0 8.09888 2.31079 10.4348 5.16129 10.4348Z"
      transform="translate(7.74194 6.78259)"
      fill="#E7001A"
    />
    <path
      d="M5.16129 10.4348C8.01179 10.4348 10.3226 8.09888 10.3226 5.21739C10.3226 2.33591 8.01179 0 5.16129 0C2.31079 0 0 2.33591 0 5.21739C0 8.09888 2.31079 10.4348 5.16129 10.4348Z"
      transform="translate(13.9355 6.78259)"
      fill="#F49B1C"
    />
    <path
      opacity="0.3"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.06452 0C3.31814 0.951867 4.12903 2.46735 4.12903 4.17429C4.12903 5.88124 3.31814 7.39672 2.06452 8.34859C0.810894 7.39672 0 5.88124 0 4.17429C0 2.46735 0.810894 0.951867 2.06452 0Z"
      transform="translate(13.9355 7.82568)"
      fill="#E7001A"
    />
  </svg>
);
export const Visa = () => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 32 24"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m28 0h-24c-2.20914 0-4 1.79086-4 4v16c0 2.2091 1.79086 4 4 4h24c2.2091 0 4-1.7909 4-4v-16c0-2.20914-1.7909-4-4-4z"
      fill="#27337a"
    />
    <path
      d="m9.30917 4.08183c-.01027-.83578.72153-1.30221 1.27293-1.5795.5664-.28455.7567-.46699.7545-.72141-.0043-.38944-.4518-.56128-.8707-.56798-.73081-.01171-1.15565.20365-1.49347.36656l-.26322-1.271521c.3389-.161243.96642-.30184175 1.61719-.307979 1.5275 0 2.5269.778315 2.5323 1.98512.0059 1.53152-2.0523 1.61633-2.0383 2.30091.0049.20755.1968.42905.6173.4854.2081.02846.7826.05022 1.434-.25943l.2556 1.23024c-.3502.13167-.8005.25776-1.361.25776-1.4377 0-2.44902-.78892-2.45713-1.91817zm6.27473 1.81216c-.2789 0-.514-.16793-.6189-.4257l-2.182-5.3779046h1.5264l.3038.8664676h1.8653l.1762-.8664676h1.3453l-1.174 5.8036046zm.2135-1.56779.4405-2.17928h-1.2064zm-8.33893 1.56779-1.20317-5.8036046h1.45451l1.20263 5.8036046zm-2.15176 0-1.51396-3.95015-.6124 3.35874c-.07189.37494-.35566.59141-.67077.59141h-2.4749874l-.0345926-.16849c.508078-.11382 1.08534-.29738 1.43505-.49377.21404-.11996.27512-.22485.34538-.50995l1.15993-4.6313946h1.53721l2.35661 5.8036046z"
      fill="#fff"
      transform="matrix(1 0 0 -1 7 15)"
    />
  </svg>
);
export const Klarna = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100"
    height="40.449"
    viewBox="0 0 100 40.449"
  >
    <g data-name="Lager 2">
      <g data-name="Layer 1">
        <rect
          width="100"
          height="40.449"
          className="cls-1"
          rx="11.738"
          fill="#ffb3c7"
        ></rect>
        <path d="M80.077 17.724a6.194 6.194 0 100 10.237v.75h3.515V16.973h-3.515zm-3.196 8.138a3.024 3.024 0 113.185-3.02 3.106 3.106 0 01-3.185 3.02z"></path>
        <path d="M28.203 11.742H31.881V28.711000000000002H28.203z"></path>
        <path d="M64.154 16.657a4.27 4.27 0 00-3.62 1.638v-1.322h-3.5v11.738h3.542v-6.169a2.475 2.475 0 012.639-2.659c1.544 0 2.432.923 2.432 2.635v6.193h3.51v-7.465c0-2.731-2.171-4.59-5.003-4.59zM43 17.724a6.194 6.194 0 100 10.237v.75h3.514V16.973H43zm-3.196 8.138a3.024 3.024 0 113.184-3.02 3.106 3.106 0 01-3.185 3.02zM51.951 18.502v-1.53h-3.598v11.74h3.606v-5.48c0-1.85 2.004-2.844 3.395-2.844l.042.002v-3.416a4.382 4.382 0 00-3.445 1.528zM87.323 24.548a2.206 2.206 0 102.206 2.206 2.206 2.206 0 00-2.206-2.206zM25.668 11.738h-3.812a9.749 9.749 0 01-3.941 7.868l-1.51 1.131 5.85 7.98h4.811l-5.383-7.343a13.505 13.505 0 003.985-9.636z"></path>
        <path d="M12.12 11.738H15.937999999999999V28.715H12.12z"></path>
      </g>
    </g>
  </svg>
);
export const GiftCard = () => (
  <svg
    width="64px"
    height="48px"
    viewBox="0 0 64 48"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="bb">
        <g id="Group-7">
          <rect
            id="Rectangle"
            fill="#5A6831"
            x="0"
            y="0"
            width="64"
            height="48"
            rx="8"
          ></rect>
          <g
            id="Group-6"
            transform="translate(19.000000, 11.000000)"
            fill="#FFFFFF"
          >
            <path
              d="M17.8812,18.5258 L19.8012,18.5258 C20.7772,18.5258 21.2492,18.0568 21.2492,17.0818 L21.2492,16.3978 C21.2492,15.3118 20.7412,14.8048 19.6552,14.8048 L17.8812,14.8048 L17.8812,18.5258 Z M19.7282,3.5058 L17.8812,3.5058 L17.8812,11.2818 L19.5852,11.2818 C20.6712,11.2818 21.1762,10.7218 21.1762,9.6378 L21.1762,4.9518 C21.1762,3.9778 20.7062,3.5058 19.7282,3.5058 L19.7282,3.5058 Z M25.0822,16.6868 L25.0822,17.2258 C25.0822,20.3708 23.5262,21.9278 20.3792,21.9278 L14.0512,21.9288 L14.0512,0.1048 L20.3062,0.1048 C23.4552,0.1048 25.0122,1.6588 25.0122,4.8078 L25.0122,9.0728 C25.0122,11.4608 24.2502,12.4378 22.8392,12.9428 C24.3612,13.4508 25.0822,14.6248 25.0822,16.6868 L25.0822,16.6868 Z"
              id="Fill-2"
            ></path>
            <path
              d="M4.7205,11.2868 L6.4785,11.2868 C7.5555,11.2868 8.0595,10.7828 8.0595,9.7058 L8.0595,4.8588 C8.0595,3.8888 7.5905,3.4238 6.6225,3.4238 L4.7205,3.4238 L4.7205,11.2868 Z M7.9875,21.1388 L7.9875,16.3418 C7.9875,15.2628 7.4855,14.7618 6.4085,14.7618 L4.7205,14.7618 L4.7205,22.5768 L6.5505,22.5768 C7.5205,22.5768 7.9875,22.1118 7.9875,21.1388 L7.9875,21.1388 Z M9.6375,13.2558 C11.0365,13.7578 11.7925,14.8708 11.7925,17.0958 L11.7925,21.2858 C11.7925,24.4088 10.2475,25.9508 7.1235,25.9508 L0.9185,25.9508 L0.9185,0.0488 L7.1955,0.0488 C10.3185,0.0488 11.8615,1.5958 11.8615,4.7158 L11.8615,9.6288 C11.8615,11.6738 11.1465,12.7518 9.6375,13.2558 L9.6375,13.2558 Z"
              id="Fill-4"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export const Sofort = () => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 32 24"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m28 0h-24c-2.20914 0-4 1.79086-4 4v16c0 2.2091 1.79086 4 4 4h24c2.2091 0 4-1.7909 4-4v-16c0-2.20914-1.7909-4-4-4z"
      fill="#ff821e"
    />
    <path
      clipRule="evenodd"
      d="m10.1692 0c-.91938 0-1.8125-.00007067-2.61287.399207-.79979.399293-1.50628 1.198473-2.04997 2.796793-.11784.34364-.18017.63971-.18834.89673-.00816.25759.03736.47671.13653.66718.1225.23235.32085.45094.56878.6351.24852.18473.54537.33452.86564.43033.01225.00344.18033.05045.38451.10782.20301.05737.4404.12614.59091.17089.08808.02639.18484.06085.27235.11306.08808.05106.16689.11986.21648.21509.03266.06311.05019.13255.05194.20656.00233.07458-.01057.15497-.04032.23988-.07467.21686-.18785.35683-.4037.44231-.21468.08548-.53141.11292-1.0121.11636h-6.000508l-.948532 2.56269h6.42282c.60437 0 1.70924.0001 2.77097-.44396 1.06171-.44347 2.08041-1.32984 2.51151-3.10428.1622-.66664.1119-1.20994-.1535-1.63677-.2666-.42625-.7484-.73599-1.4502-.93391 0 0-.1569-.04488-.34183-.09708-.18376-.05221-.39671-.11182-.50521-.1428-.17968-.05049-.31796-.15262-.39963-.28285-.08109-.12966-.1056-.28743-.05952-.44864.04842-.16752.16512-.3012.33312-.39356.16918-.0918.39085-.14225.64986-.14225h3.73351c.0262-.03729.0502-.07797.0764-.11526.682-.9489 1.5075-1.716496 2.4117-2.25864z"
      fill="#fff"
      fillRule="evenodd"
      transform="translate(8 7)"
    />
  </svg>
);

const PaymentMethodWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.color.neutralDark};
  border-radius: 5px;
  padding: ${({ theme }) =>
    `0.7rem ${theme.padding.md} ${theme.padding.md} ${theme.padding.md}`};
  margin-bottom: ${({ theme }) => theme.padding.md};
`;

export type PaymentMethodFieldProps = {
  nameIssuer: string;
  fieldName: string;
  methods: PaymentMethodOption[];
  onChange: (paymentMethod: string) => void;
} & FieldProps;

export type PaymentMethodIssuerOption = {
  label: string;
  name: string;
};

export type PaymentMethodOption = {
  label: string;
  name: string;
  issuers?: PaymentMethodIssuerOption[];
};

export type PaymentMethodProps = {
  label: string;
  name: string;
  fieldName: string;
  fieldNameIssuer: string;
  options?: PaymentMethodIssuerOption[];
  onChange?: (paymentMethod: string) => void;
};

export type PaymentMethodFormValues = {
  method: string;
};

export const PaymentMethodIcon = styled.div`
  > * {
    width: 70px;
    height: 70px;
  }
`;

export const PaymentMethodLabel = styled(Label)`
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.1em;
`;

const getPaymentMethodIcon = (paymentMethod: string) => {
  switch (paymentMethod) {
    case "ideal": {
      return <Ideal />;
    }
    case "visa": {
      return <Visa />;
    }
    case "mastercard": {
      return <Mastercard />;
    }
    case "bancontact": {
      return <Bancontact />;
    }
    case "klarna": {
      return <Klarna />;
    }
    case "sofort": {
      return <Sofort />;
    }
    case "intersolve": {
      return <GiftCard />;
    }
    default: {
      return null;
    }
  }
};

const PaymentMethodIconCol = styled(Col)`
  text-align: right;
`;

export const PaymentMethod: React.FC<PaymentMethodProps> = ({
  label,
  name,
  options,
  fieldName,
  fieldNameIssuer,
  onChange,
}) => {
  const { values } = useFormikContext<PaymentMethodFormValues>();

  const selectOptions = (options || []).map(
    (option: PaymentMethodIssuerOption) => ({
      label: option.label,
      value: option.name,
    })
  );

  return (
    <PaymentMethodWrapper>
      <Row>
        <Col col={8}>
          <FormGroup inline>
            <Field
              name={fieldName}
              value={name}
              id={name}
              component={RadioField}
              onChange={onChange}
            />
            <PaymentMethodLabel htmlFor={name}>{label}</PaymentMethodLabel>
          </FormGroup>
        </Col>
        <PaymentMethodIconCol col={4}>
          <PaymentMethodIcon>{getPaymentMethodIcon(name)}</PaymentMethodIcon>
        </PaymentMethodIconCol>
      </Row>
      {values[fieldName] === name && options && options.length > 0 && (
        <Field
          defaultValue={selectOptions[0]}
          name={fieldNameIssuer}
          options={selectOptions}
          component={SelectField}
          size="small"
        />
      )}
    </PaymentMethodWrapper>
  );
};

const PaymentMethodsField: React.FC<PaymentMethodFieldProps> = ({
  methods,
  field: { name },
  nameIssuer,
  onChange,
}) => (
  <>
    {methods.map((option: PaymentMethodOption) => (
      <PaymentMethod
        key={option.name}
        fieldName={name}
        fieldNameIssuer={nameIssuer}
        label={option.label}
        name={option.name}
        options={option.issuers}
        onChange={onChange}
      />
    ))}
  </>
);

export default PaymentMethodsField;
