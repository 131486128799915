export default {
  // Buttons
  "button.add": "Add",
  "button.apply": "Apply",
  "button.automatic_redirect":
    "Click here if you are not automatically redirected.",
  "button.checkout_next": "Continue with ordering",
  "button.disclaimer": "Disclaimer",
  "button.download_tickets": "Download tickets",
  "button.modify": "Modify",
  "button.more_info": "More info",
  "button.order_edit": "Change order",
  "button.order_new": "New order",
  "button.park_regulations": "Park Regulations",
  "button.payment_submit": "Complete",
  "button.privacy_statement": "Privacy Statement",
  "button.remove": "Remove",
  "button.select_all": "Select all",
  "button.select_days":
    "{count, plural, =0 {Select days} =1 {1 day selected} other {# days selected}}",
  "button.to_products": "To the tickets",
  "button.voucher_redeem": "Redeem voucher",
  "button.voucher_additional": "One more code",

  // Errors
  "error.checkout_invalid":
    "Your order is not yet complete. Change your order and place it again.",
  "error.max_tickets_reached": "You have reached the maximum number of tickets",
  "error.max_timeframe_reached":
    "You have reached the maximum number of tickets for this timeslot",
  "error.order_confirmation_failed":
    "An error occurred during order confirmation.",
  "error.paymentmethods_fetch_failed":
    "An error occurred while retrieving the payment methods.",
  "error.products_fetch_failed":
    "An error occurred while retrieving the products. Try again or contact us on {phoneNumber}",
  "error.voucher_already_exists": "You have already entered this voucher",
  "error.voucher_redeem_failed": "Unfortunately, this voucher is invalid",
  "error.voucher_validation_failed":
    "An error occurred while validating your voucher",
  "error.voucher_maximum_vouchers_reached":
    "You have entered the maximum number of voucher codes.",

  // Forms
  "form.customer.subtitle":
    "We need the information below to send your tickets digitally.",
  "form.customer.title": "Personal information",

  // Form help
  "help.email":
    "Your tickets are sent to this email address. Not received, check your junk mail.",
  "help.phone":
    "Due to new regulations of the RIVM (Dutch National Institute for Public Health), we ask for your telephone number for a possible source and contact investigation. It goes without saying that the data will not be used for other purposes.",

  // Form labels
  "label.agree_with": "You must agree to the",
  "label.almost_sold_out": "Almost full",
  "label.and": "and",
  "label.choose_quantity": "Choose quantity",
  "label.closed": "Closed",
  "label.email": "E-mail address",
  "label.email_verify": "E-Mail adress (verification)",
  "label.fields_required": "* This field is mandatory",
  "label.first_name": "First name",
  "label.last_name": "Last name",
  "label.legend": "Legend",
  "label.newsletter": "I would like to receive the newsletter",
  "label.no": "No",
  "label.park_regulations": "park regulations",
  "label.phone": "Phone number",
  "label.privacy_policy": "privacy policy",
  "label.quantity": "Quantity",
  "label.selected_day": "Selected day",
  "label.select_days": "Select days",
  "label.sold_out": "Sold out",
  "label.terms": "terms and conditions",
  "label.terms_aviodrome": "terms and conditions and our hygiene policy",
  "label.timeframe_from_util": "Between {from} & {until}",
  "label.today": "Today",
  "label.voucher_code": "Voucher Code",
  "label.yes": "Yes",

  "placeholder.voucher": "Your voucher code",

  // Routes
  "route.checkout": "/en/checkout",
  "route.payment_failed_aquazoo": "/en-page-error",
  "route.payment_failed_beeksebergen": "/en/page-error",
  "route.payment_failed_dierenrijk": "/en-page-error",
  "route.payment_failed_speelland": "/en/page-error",
  "route.payment_failed_zooparc": "/en-page-error",
  "route.payment_success_aquazoo": "/en-thank-you-for-your-order",
  "route.payment_success_beeksebergen": "/en/thank-you-for-your-order",
  "route.payment_success_dierenrijk": "/en-thank-you-for-your-order",
  "route.payment_success_speelland": "/en/thank-you-for-your-order",
  "route.payment_success_zooparc": "/en-thank-you-for-your-order",

  // Titles
  "title.404": "Page not found",
  "title.404_subtitle":
    "This page does not exist (anymore), or there is a mistake in the address. Check if the address is spelled correctly",
  "title.additional_products": "Extra products",
  "title.arrival_date": "Select your arrival date",
  "title.checkout_redirect": "Verifying payment",
  "title.checkout_redirect_subtitle":
    "We are verifying your payment. You will be redirect shorly",
  "title.free": "Free",
  "title.libema_footer":
    "You will have a great time at {account}, which is part of",
  "title.order_number":
    "Your order number: <strong>#{orderNumber}</strong>. Use this number if you contact us.",
  "title.order_verification_cancelled": "Oops, this takes too long.",
  "title.order_verification_cancelled_subtitle":
    "Your payment has been received, but your order has not yet been processed. This takes longer than usual. If you do not receive an e-mail within a few minutes, please contact us at +31 (0)88-9000360.",
  "title.payment_failed": "Oops... Something went wrong!",
  "title.payment_failed_subtitle":
    "Unfortunately, something went wrong. Please try again! Do you need help? Call us at +31 (0)88-9000360 (Mon-Fri until 6:00 pm, Sat-Sun until 5:00 pm).",
  "title.payment_method": "Choose your payment method",
  "title.payment_method_subtitle":
    "To make your booking final, all you have to do is pay. Select one of the payment methods.",
  "title.selected_date": "Available tickets on",
  "title.shopping_cart": "Your shopping cart",
  "title.tickets": "Order your tickets",
  "title.select_dates": "Select days",
  "title.tickets_subtitle_aviodrome": "",
  "title.tickets_subtitle_aquazoo":
    "Are you ready to embark on an expedition? Children under the age of 3 are allowed to explore the park for free. <br />Over the age of 3? Then you can benefit from our online discount.",
  "title.tickets_subtitle_beeksebergen":
    "Are you ready to embark on a safari? Free admission for children under 3 years old. <br />Over the age of 3? Then you can benefit from our online discount.",
  "title.tickets_subtitle_dierenrijk":
    "Discover the animal within you and visit Dierenrijk. Children under 3 get to go wild for free. <br />Over the age of 3? Then you can benefit from our online discount.",
  "title.tickets_subtitle_speelland":
    "Are you ready for a great day of fun and play? Free admission for children under 3 years old. Over the age of 3? Then you can benefit from our online discount.",
  "title.tickets_subtitle_zooparc":
    "Are you ready to embark on an expedition? Children under the age of 3 are allowed to explore the park for free. <br />Over the age of 3? Then you can benefit from our online discount.",
  "title.timeframes": "Choose your arrival time",
  "title.timeframes_subtitle":
    "Due to the current situation regarding the coronavirus, we work with time slots. In this way, queues can be avoided. Choose your preferred arrival time here.",
  "title.total": "Total",
  "title.unfinished_order":
    "Unfortunately, it is not possible to place multiple orders at the same time. Complete the other order first or click 'New order'. The other order will then be canceled.",
  "title.vouchers": "Tickets with voucher codes",
  "title.vouchers_subtitle":
    "If you have received any vouchercodes, enter them below",
  "title.your_order": "Your order",
  "title.your_order_subtitle":
    "We are almost there! But not quite… Check your order, finish your order and receive it directly in your inbox.",

  // Form validators
  "validator.email_invalid": "Invalid email address",
  "validator.email_mismatch": "The email addresses do not match",
  "validator.email_required": "The email field is required",
  "validator.first_name_required": "The first name field is required",
  "validator.ideal_issuer_required":
    "The iDEAL payment method requires the selection of your bank",
  "validator.last_name_required": "The last name field is required",
  "validator.min_addition_dates": "Select at least one date.",
  "validator.min_addition_quantity":
    "This extra option can only be booked with a minimum amount of {minQuantity}.",
  "validator.payment_method_required": "You must select a payment method",
  "validator.privacy_required": "You must agree to the privacy policy",
  "validator.products_required": "You have not selected a product",
  "validator.required": "This field is mandatory",
  "validator.terms_required": "You must agree to the terms and conditions",

  // Warnings
  "warning.no_products": "No product selected",
  "warning.no_timeframes":
    "We do not have any availability anymore on this day. Please have a look at another day.",
  "warning.old_browser":
    'You are using an <strong>outdated</strong> browser. Please <a href="https://browsehappy.com/">upgrade your browser</a> to improve your experience and security.',
};
