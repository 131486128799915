export default {
  // Buttons
  "button.add": "Ajouter",
  "button.apply": "Appliquer",
  "button.automatic_redirect":
    "Cliquez ici si vous n'êtes pas redirigé(e) automatiquement.",
  "button.checkout_next": "Poursuivre la commande",
  "button.disclaimer": "Clause de non-responsabilité",
  "button.download_tickets": "Télécharger des billets",
  "button.modify": "Modifier",
  "button.more_info": "Information",
  "button.order_edit": "Modifier la commande",
  "button.order_new": "Nouvelle commande",
  "button.park_regulations": "Règlement du parc",
  "button.payment_submit": "Finaliser la commande",
  "button.privacy_statement": "Déclaration de confidentialité",
  "button.remove": "Supprimer",
  "button.select_all": "Sélectionner tout",
  "button.select_days":
    "{count, plural, =0 {Sélectionner les jours} =1 {1 jour sélectionné} other {# jours sélectionnés}}",
  "button.to_products": "Voir les billets",
  "button.voucher_redeem": "Contrôler",
  "button.voucher_additional": "Ajouter un code",

  // Errors
  "error.checkout_invalid":
    "Votre commande n’est pas encore complète. Modifiez votre commande puis confirmez-la de nouveau.",
  "error.max_tickets_reached":
    "Vous avez atteint le nombre de billets maximum par personne.",
  "error.max_timeframe_reached":
    "Le nombre maximum de billets disponibles pour ce créneau horaire est atteint.",
  "error.order_confirmation_failed":
    "Une erreur s'est produite lors de la confirmation de commande",
  "error.paymentmethods_fetch_failed":
    "Une erreur s'est produite lors de l'affichage des modes de paiement.",
  "error.products_fetch_failed":
    "Une erreur s'est produite lors de l’affichage des produits. Réessayez ou contactez-nous au {phoneNumber}.",
  "error.voucher_redeem_failed":
    "Le code du coupon communiqué n'est malheureusement pas valide.",
  "error.voucher_already_exists": "Vous avez déjà utilisé ce code",
  "error.voucher_validation_failed":
    "Une erreur est survenue lors de la validation de votre code",

  // Forms
  "form.customer.subtitle":
    "Les informations ci-dessous nous sont nécessaires pour vous envoyer vos billets électroniques.",
  "form.customer.title": "Informations personnelles",

  // Form help
  "help.email":
    "Vos billets seront envoyés à cette adresse e-mail. Non reçu, vérifiez votre courrier indésirable.",
  "help.phone":
    "En raison des nouvelles réglementations du RIVM (Dutch National Institute for Public Health), nous vous demandons votre numéro de téléphone pour une éventuelle recherche de source et de contact. Il va sans dire que les données ne seront pas utilisées à d'autres fins.",

  // Form labels
  "label.agree_with": "Vous devez accepter (le/la/les…)",
  "label.almost_sold_out": "Presque complet",
  "label.and": "et",
  "label.choose_quantity": "Choisir la quantité",
  "label.closed": "Fermé",
  "label.email": "Adresse électronique",
  "label.email_verify": "Adresse électronique (Vérification)",
  "label.fields_required": "* Ces champs sont obligatoires.",
  "label.first_name": "Prénom",
  "label.last_name": "Nom de famille",
  "label.legend": "Légende",
  "label.newsletter": "Je souhaite recevoir la newsletter.",
  "label.no": "Non",
  "label.park_regulations": "Règlement du parc",
  "label.phone": "Numéro de téléphone",
  "label.privacy_policy": "Déclaration de confidentialité",
  "label.quantity": "La quantité",
  "label.selected_day": "Jour sélectionné",
  "label.select_days": "Sélectionnez les jours",
  "label.sold_out": "Complet",
  "label.terms": "Conditions générales",
  "label.timeframe_from_util": "Entre {from} & {until} heure.",
  "label.today": "Aujourd'hui",
  "label.voucher_code": "Code du coupon",
  "label.yes": "Oui",

  "placeholder.voucher": "Votre code du coupon",

  // Routes
  "route.checkout": "/fr/checkout",
  "route.payment_failed_beeksebergen": "/fr/message-derreur",
  "route.payment_failed_speelland": "/fr/message-derreur",
  "route.payment_success_beeksebergen": "/fr/merci-pour-votre-commande",
  "route.payment_success_speelland": "/fr/merci-pour-votre-commande",

  // Titles
  "title.404": "Page introuvable",
  "title.404_subtitle":
    "Cette page n'existe pas (ou plus), ou il y a une faute de frappe dans l'adresse. Veuillez vérifier si l'adresse est correctement orthographiée.",
  "title.additional_products": "Suppléments",
  "title.arrival_date": "Sélectionnez votre date d'arrivée",
  "title.checkout_redirect": "Vérifier le commande.",
  "title.checkout_redirect_subtitle":
    "Nous vérifions votre commande et vous redirigerons ensuite vers le site Web.",
  "title.free": "Gratuit",
  "title.libema_footer":
    "Venez vivre les plus belles aventures à {account}, du groupe",
  "title.order_number":
    "Votre numéro de commande: <strong>#{orderNumber}</strong>. Utilisez ce numéro si vous nous contactez.",
  "title.order_verification_cancelled": "Oups, cela prend trop de temps.",
  "title.order_verification_cancelled_subtitle":
    "Votre paiement a été reçu, mais votre commande n'a pas encore été traitée. Cela prend plus de temps que d'habitude. Si vous ne recevez pas d'e-mail dans quelques minutes, veuillez nous contacter au +31 (0)88-9000360.",
  "title.payment_failed": "Oups.. Une erreur s'est produite !",
  "title.payment_failed_subtitle":
    "Nous regrettons de vous informer qu'une erreur s'est produite. Merci de réessayer de nouveau ! Si vous avez besoin d'aide, appelez-nous au +31 (0)88-90 00 360 (jusqu'à 18h00 du lundi au vendredi, et jusqu'à 17h00 le samedi et le dimanche).",
  "title.payment_method": "Choisissez votre mode de paiement",
  "title.payment_method_subtitle":
    "Il ne vous reste plus qu’à régler votre commande pour finaliser votre réservation. Sélectionnez l'un des modes de paiement.",
  "title.selected_date": "Billets disponibles sur",
  "title.select_dates": "Sélectionnez les jours",
  "title.shopping_cart": "Votre panier",
  "title.tickets": "Commandez vos billets",
  "title.tickets_subtitle_beeksebergen":
    "Vous êtes prêts à partir en safari ? L'entrée est gratuite pour les enfants jusqu'à 3 ans. <br />Après 3 ans, vous pouvez profiter de nos tarifs préférentiels en ligne.",
  "title.tickets_subtitle_speelland":
    "Vous êtes prêt à passer une journée pleine d'amusement ? L'entrée est gratuite pour les enfants jusqu'à 3 ans. Après 3 ans, vous pouvez profiter de nos tarifs préférentiels en ligne.",
  "title.timeframes": "Choisissez votre horaire d'arrivée.",
  "title.timeframes_subtitle":
    "Étant donné la situation actuelle liée au coronavirus, nous fonctionnons avec un système de créneaux horaires. Cela nous permet d'éviter les files d'attente. Choisissez ici votre horaire d'arrivée.",
  "title.total": "Total",
  "title.unfinished_order":
    "Malheureusement, il n'est pas possible de passer plusieurs commandes en même temps. Complétez d'abord l'autre commande ou cliquez sur 'Nouvelle commande'. L'autre commande sera alors annulée.",
  "title.vouchers": "Billets avec coupons",
  "title.vouchers_subtitle":
    "Si vous avez reçu un coupon, veuillez en renseigner le code ci-dessous.",
  "title.your_order": "Votre commande",
  "title.your_order_subtitle":
    "Tu as presque fini! Mais pas encore ... Vérifiez votre commande, complétez votre commande et recevez-la directement dans votre boîte de réception.",

  // Form validators
  "validator.email_invalid": "Adresse mail non valide",
  "validator.email_mismatch": "Les adresses e-mail ne correspondent pas",
  "validator.email_required": "Le champ Adresse mail est obligatoire.",
  "validator.first_name_required": "Le champ Prénom est obligatoire.",
  "validator.ideal_issuer_required":
    "Pour utiliser le mode de paiement iDEAL, veuillez sélectionner votre banque.",
  "validator.last_name_required": "Le champ Nom de famille est obligatoire.",
  "validator.min_addition_dates": "Sélectionnez au moins une date.",
  "validator.min_addition_quantity":
    "Cette option supplémentaire ne peut être réservé qu'avec un nombre minimum de {minQuantity}.",
  "validator.payment_method_required":
    "Vous devez sélectionner un mode de paiement.",
  "validator.privacy_required":
    "Vous devez accepter la Déclaration de confidentialité.",
  "validator.products_required": "Vous n'avez sélectionné aucun produit.",
  "validator.required": "Ce champ est obligatoire",
  "validator.terms_required": "Veuillez accepter les conditions générales",

  // Warnings
  "warning.no_products": "Aucun produit sélectionné",
  "warning.no_timeframes":
    "Cette journée n’a malheureusement plus aucune disponibilité. Choisissez vite un autre jour.",
  "warning.old_browser":
    'Vous utilisez un navigateur <strong>obsolète</strong>. Veuillez <a href="https://browsehappy.com/">actualiser votre navigateur</a> pour améliorer votre expérience et votre sécurité.',
};
