export default {
  // Buttons
  "button.add": "Toevoegen",
  "button.apply": "Toepassen",
  "button.automatic_redirect":
    "Klik hier wanneer je niet direct doorgestuurd wordt.",
  "button.back_to_referral": "< Terug naar {referral}",
  "button.checkout_next": "Verder met bestellen",
  "button.disclaimer": "Disclaimer",
  "button.download_tickets": "Download tickets",
  "button.modify": "Wijzigen",
  "button.more_info": "Meer info",
  "button.order_edit": "Bestelling wijzigen",
  "button.order_new": "Nieuwe bestelling",
  "button.park_regulations": "Parkreglement",
  "button.payment_submit": "Afronden",
  "button.privacy_statement": "Privacy Statement",
  "button.remove": "Verwijderen",
  "button.select_days":
    "{count, plural, =0 {Selecteer dagen} =1 {1 dag geselecteerd} other {# dagen geselecteerd}}",
  "button.select_all": "Selecteer alles",
  "button.to_products": "Naar de tickets",
  "button.voucher_redeem": "Controleer",
  "button.voucher_additional": "Nog een code",

  // Errors
  "error.api.timeframe_expired":
    "Één van de gekozen tijdssloten is inmiddels gesloten. Selecteer een ander tijdsslot",
  "error.api.timeframe_full":
    "Één van de gekozen tijdssloten is helaas vol. Selecteer een ander tijdsslot",
  "error.api.unexpected":
    "Er is een fout opgetreden. Probeer het later opnieuw of neem contact op via {phoneNumber}",
  "error.api.validation_failed":
    "Er is iets mis met je order. Probeer het later opnieuw of neem contact op via {phoneNumber}",
  "error.checkout_invalid":
    "Je bestelling is nog niet volledig. Wijzig je bestelling en plaats hem opnieuw.",
  "error.max_tickets_reached":
    "Je hebt het maximum aantal tickets per persoon bereikt.",
  "error.max_timeframe_reached":
    "Het maximum aantal tickets voor dit tijdsslot is bereikt.",
  "error.order_confirmation_failed":
    "Er is een fout opgetreden tijdens de order bevestiging.",
  "error.paymentmethods_fetch_failed":
    "Er is een fout opgetreden tijdens het opvragen van de betaalmethoden.",
  "error.products_fetch_failed":
    "Er is een fout opgetreden tijdens het opvragen van de producten. Probeer het opnieuw of neem contact op via {phoneNumber}",
  "error.timeframes_fetch_failed":
    "Er is een fout opgetreden tijdens het opvragen van de beschikbare dagen. Probeer het opnieuw of neem contact op via {phoneNumber}",
  "error.voucher_redeem_failed": "Deze kortingscode is helaas niet geldig.",
  "error.voucher_already_exists": "Deze kortingscode heb je al ingevuld.",
  "error.voucher_validation_failed":
    "Er is een fout opgetreden tijdens het valideren van je kortingscode.",
  "error.voucher_maximum_vouchers_reached":
    "Je hebt het maximum aantal kortingscodes bereikt",

  // Forms
  "form.customer.subtitle":
    "Onderstaande gegevens hebben wij nodig om jouw tickets digitaal te versturen.",
  "form.customer.title": "Persoonlijke informatie",

  // Form help
  "help.email":
    "Je tickets worden naar dit e-mailadres gestuurd. Niet ontvangen, controleer je ongewenste mail.",
  "help.phone":
    "Na aanscherping van de richtlijnen van het RIVM, vragen wij jouw telefoonnummer voor een eventueel bron- en contactonderzoek. Vanzelfsprekend zullen de gegevens niet voor andere doeleinden gebruikt worden.",

  // Form labels
  "label.agree_with": "Je dient akkoord te gaan met de",
  "label.almost_sold_out": "Bijna vol",
  "label.and": "en",
  "label.choose_quantity": "Kies aantal",
  "label.closed": "Gesloten",
  "label.email": "E-mail adres",
  "label.email_verify": "E-mail adres (verificatie)",
  "label.fields_required": "* Deze velden zijn verplicht",
  "label.first_name": "Voornaam",
  "label.last_name": "Achternaam",
  "label.legend": "Legenda",
  "label.newsletter": "Ik wil graag de nieuwsbrief ontvangen",
  "label.no": "Nee",
  "label.park_regulations": "parkreglement",
  "label.phone": "Telefoonnummer",
  "label.privacy_policy": "privacy statement",
  "label.quantity": "Aantal",
  "label.selected_day": "Geselecteerd",
  "label.select_days": "Selecteer dagen",
  "label.sold_out": "Vol",
  "label.terms": "algemene voorwaarden",
  "label.terms_aviodrome": "algemene voorwaarden en ons hygiënebeleid",
  "label.timeframe_from_util": "Tussen {from} & {until} uur",
  "label.today": "Vandaag",
  "label.voucher_code": "Kortingscode",
  "label.yes": "Ja",

  "placeholder.voucher": "Je kortingscode",

  // Routes
  "route.checkout": "/checkout",
  "route.payment_failed_aviodrome": "/tickets/foutmelding",
  "route.payment_failed_aquazoo": "/foutmelding",
  "route.payment_failed_beeksebergen": "/tickets/foutmelding",
  "route.payment_failed_dierenrijk": "/foutmelding",
  "route.payment_failed_speelland": "/foutmelding",
  "route.payment_failed_zooparc": "/foutmelding",
  "route.payment_success_aviodrome": "/tickets/bedankt-voor-uw-aankoop",
  "route.payment_success_aquazoo": "/bedankt-voor-uw-aankoop",
  "route.payment_success_beeksebergen": "/bedankt-voor-uw-aankoop",
  "route.payment_success_dierenrijk": "/bedankt-voor-uw-aankoop",
  "route.payment_success_speelland": "/bedankt-voor-uw-aankoop",
  "route.payment_success_zooparc": "/bedankt-voor-uw-aankoop",

  // Titles
  "title.404": "Pagina niet gevonden",
  "title.404_subtitle":
    "Deze pagina bestaat niet (meer), of er zit een typfout in het adres. Controleer of het adres juist is gespeld",
  "title.additional_products": "Extra's",
  "title.another_voucher": "Nog een kortingscode controleren",
  "title.arrival_date": "Selecteer je aankomstdag",
  "title.checkout_redirect": "Bestelling verifiëren",
  "title.checkout_redirect_subtitle":
    "Wij verifiëren de bestelling en sturen je zo door naar de website.",
  "title.free": "Gratis",
  "title.libema_footer":
    "De mooiste tijd beleef je bij {account}, onderdeel van",
  "title.order_number":
    "Jouw ordernummer: <strong>#{orderNumber}</strong>. Gebruik dit nummer als je contact met ons opneemt.",
  "title.order_verification_cancelled": "Oeps, dit duurt te lang.",
  "title.order_verification_cancelled_subtitle":
    "Je betaling is ontvangen, echter is je order nog niet verwerkt. Dit duurt langer dan gebruikelijk. Als je binnen enkele minuten geen e-mail ontvangt, neem dan contact met ons via 088-9000360",
  "title.payment_failed": "Oeps... Er is iets mis gegaan!",
  "title.payment_failed_subtitle":
    "Helaas is er iets mis gegaan. Probeer het nog eens! Heb je hulp nodig? Bel ons via 088-9000360 (ma-vrij tot 18.00 uur, za-zo tot 17.00 uur).",
  "title.payment_method": "Kies je betaalmethode",
  "title.payment_method_subtitle":
    "Om je boeking definitief te maken hoef je alleen nog maar af te rekenen. Selecteer een van de betaalmethoden.",
  "title.selected_date": "Beschikbare tickets op",
  "title.select_dates": "Selecteer dagen",
  "title.shopping_cart": "Je winkelmandje",
  "title.tickets": "Bestel je tickets",
  "title.tickets_subtitle_aviodrome":
    "De tijd vliegt voorbij in Aviodrome! Kinderen tot 3 jaar 'vliegen' gratis naar binnen. <br />Ouder dan 3 jaar? Dan profiteer je van onze online korting.",
  "title.tickets_subtitle_aquazoo":
    "Ben jij klaar voor een spetterende dag in AquaZoo? Kinderen tot 3 jaar mogen gratis 'een duik nemen' in het park. <br />Ouder dan 3 jaar? Dan profiteer je van onze online korting.",
  "title.tickets_subtitle_beeksebergen":
    "Ben je klaar om op safari te gaan? Kinderen tot 3 jaar krijgen gratis toegang. <br />Ouder dan 3 jaar? Dan profiteer je van onze online korting.",
  "title.tickets_subtitle_dierenrijk":
    "Ontdek het dier in jezelf en kom naar Dierenrijk. Kinderen tot 3 jaar hangen gratis de beest uit. <br />Ouder dan 3 jaar? Dan profiteer je van onze online korting.",
  "title.tickets_subtitle_speelland":
    "Ben je klaar voor een dag vol speelplezier? Kinderen tot 3 jaar krijgen gratis toegang. Ouder dan 3 jaar? Dan profiteer je van onze online korting.",
  "title.tickets_subtitle_zooparc":
    "Ben jij klaar om op expeditie te gaan? Kinderen tot 3 jaar mogen gratis het park verkennen. <br />Ouder dan 3 jaar? Dan profiteer je van onze online korting.",
  "title.timeframes": "Kies je aankomsttijd",
  "title.timeframes_subtitle":
    "Wegens de huidige situatie rondom het coronavirus werken wij met tijdsloten. Hiermee worden wachtrijen voorkomen. Kies hier je gewenste aankomsttijd.",
  "title.total": "Totaal",
  "title.unfinished_order":
    "We zien dat je al met een betaling bent gestart, hierdoor is het niet meer mogelijk om deze bestelling aan te passen. Rond deze eerst af of kies ‘Nieuwe bestelling’.We zien dat je al met een betaling bent gestart, hierdoor is het niet meer mogelijk om deze bestelling aan te passen. Rond deze eerst af of kies ‘Nieuwe bestelling’.",
  "title.voucher_products": "Producten",
  "title.vouchers": "Tickets met kortingscodes",
  "title.vouchers_validate": "Valideer je kortingscode",
  "title.vouchers_subtitle":
    "Vul je kortingscodes hieronder in, bestel tickets met korting en ontvang deze in je mailbox. Vergeet je daadwerkelijke bezoek niet voorafgaand te reserveren via onze website.",
  "title.your_order": "Jouw bestelling",
  "title.your_order_subtitle":
    "We zijn er bijna! Maar nog niet helemaal… Controleer jouw bestelling, rond hem af en ontvang deze direct in je mailbox.",

  // Form validators
  "validator.email_invalid": "Ongeldig e-mail adres",
  "validator.email_mismatch": "De e-mailadressen komen niet overeen",
  "validator.email_required": "Het e-mail veld is verplicht",
  "validator.first_name_required": "Het voornaam veld is verplicht",
  "validator.ideal_issuer_required":
    "De iDEAL betaalmethode vereist de selectie van je bank",
  "validator.last_name_required": "Het achternaam veld is verplicht",
  "validator.min_addition_dates": "Selecteer tenminste één datum.",
  "validator.min_addition_quantity":
    "Deze extra is pas boekbaar met een minimum aantal van {minQuantity}.",
  "validator.payment_method_required":
    "Je dient een betaalmethode te selecteren",
  "validator.privacy_required":
    "Je dient akkoord te gaan met de privacy policy",
  "validator.products_required": "Je hebt geen producten geselecteerd",
  "validator.required": "Dit veld is verplicht",
  "validator.terms_required":
    "Je dient akkoord te gaan met de algemene voorwaarden",

  // Warnings
  "warning.max_voucher_products_reached":
    "U kunt helaas met deze kortingscode maar 1 ticket toevoegen",
  "warning.no_products": "Geen producten geselecteerd",
  "warning.no_timeframes":
    "Helaas is er op deze dag geen beschikbaarheid meer. Kijk snel voor een andere dag.",
  "warning.old_browser":
    'Je maakt gebruik van een <strong>verouderde</strong> browser. <a href="https://browsehappy.com/">Upgrade je browser</a> om het gebruik en de veiligheid te verbeteren.',
};
