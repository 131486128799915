import { AutoSubmitForm } from "@libema/design-system";
import {
  Alert,
  AlertType,
  Button,
  ButtonGroup,
  Center,
  Col,
  deviceUtils,
  Form,
  H1,
  H3,
  P,
  PaymentMethodsField,
  Row,
} from "@libema/design-system-legacy";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import trans from "counterpart";
import { Field, Formik } from "formik";
import { withRouter } from "next/router";
import PropTypes from "prop-types";
import { Component } from "react";
import { FormattedNumber } from "react-intl";
import { connect } from "react-redux";
import styled from "styled-components";
import WarnCancelReservationModal, {
  paymentPageVisitedState,
} from "../../../components/modals-warning/WarnCancelReservationModal";
import { handlePayment } from "../../../modules/reservation/actions";
import { checkUrl } from "../../../public/img/check";
import reservationSelectors, {
  getPaymentTerm,
} from "../../../selectors/reservationSelectors";
import analyticsUtils from "../../../utilities/analyticsUtils";
import { getPaymentMethods } from "../../../utilities/api/reservationApi";
import {
  PAYMENT_TERM_LONG,
  PAYMENT_TERM_MEDIUM,
  PAYMENT_TERM_SHORT,
} from "../../../utilities/paymentUtils";
import PaymentHeader from "./PaymentHeader";
import ReservationConfirmation from "./ReservationConfirmation";

const Container = styled.div`
  margin-bottom: 3rem;
  background-color: #fff;
`;

const CheckImg = styled.img`
  height: 2rem;
  margin-left: 0.2rem;
  position: absolute;
  left: 2.5rem;
  filter: brightness(0) invert(1);
`;

const Subtitle = styled.p`
  font-size: 1.5rem;
  font-weight: normal !important;
  color: white;
`;

const PaymentMethodSection = styled.div`
  background: white;
  padding: 1rem;
  font-size: 20px;
  align-content: center;
  @media ${deviceUtils.DEVICE_MD_UP} {
    padding: 2rem;
  }
`;

const ReservationNotice = styled.div`
  background-color: ${({ theme }) => theme.color.neutralMediumLight};
  padding: 2rem;
  z-index: 1000;
  height: 100%;
`;

const PayButton = styled(Button)`
  min-width: 16rem;
  position: relative;
`;

const CenterRow = styled(Row)`
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
  align-items: center;
`;

const initialFormValues = {
  method: "",
};

const BRAND = process.env.NEXT_PUBLIC_APP;
class PaymentBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      paymentMethods: [],
      payType: props.paymentTerm !== PAYMENT_TERM_LONG ? "full" : null,
      paymentFormData: null,
    };
  }

  componentDidMount = () => {
    analyticsUtils.handleReservationPaymentView();

    getPaymentMethods({ brand: BRAND }).then((res) => {
      const paymentMethods = res.data.map((method) => {
        return {
          ...method,
          issuers:
            method.options &&
            method.options.map((option) => ({
              name: option.issuerid,
              label: option.label,
            })),
        };
      });
      this.setState({ paymentMethods });
    });
  };

  // When amount selected display the payment methods
  handleSelectPaymentAmount = (event) => {
    this.setState({ payType: event.currentTarget.value });
  };

  handlePaymentSubmit = (values, { setSubmitting }) => {
    const {
      reservationNumber,
      reservationId,
      totalPrice,
      country,
      city,
      email,
      language,
      onPay,
      router,
      resort_id,
    } = this.props;
    const { payType } = this.state;
    const amount = payType === "partial" ? totalPrice * 0.3 : totalPrice;
    const resortId = router.query.resort ? router.query.resort : resort_id;

    const order = {
      resortId,
      payment: {
        method: values.method,
      },
      amount,
      reservation_id: reservationId,
      reservation: reservationNumber,
      email,
      country,
      city,
      language,
    };

    if (values.method === "ideal") {
      order.payment.issuerid = values.issuer;
    }

    onPay(order)
      .then((response) => {
        if (!response) {
          this.setState({ error: true });
        }
        paymentPageVisitedState.setVisited(order.reservation_id);
        // Redirect using deprecated GET url.
        if (typeof response === "string") {
          return router.push(response);
        } else {
          this.setState({ paymentFormData: response });
        }
      })
      .catch((err) => {
        this.setState({ error: true });
        console.error(err);
      });

    setSubmitting(false);
  };

  render = () => {
    const {
      totalPrice,
      reservationNumber,
      paymentTerm,
      properties: {
        text_within_6_weeks: textWithin6Weeks,
        text_within_6_8_weeks: textWithin6to8Weeks,
        text_after_8_weeks: textAfter8Weeks,
      },
    } = this.props;
    const { error, payType, paymentMethods, paymentFormData } = this.state;

    let confirmationText = "";

    switch (paymentTerm) {
      case PAYMENT_TERM_SHORT:
        confirmationText = textWithin6Weeks;
        break;
      case PAYMENT_TERM_MEDIUM:
        confirmationText = textWithin6to8Weeks;
        break;
      case PAYMENT_TERM_LONG:
        confirmationText = textAfter8Weeks;
        break;
      default:
        confirmationText = "";
    }

    if (!reservationNumber && !error) {
      return (
        <Container>
          <PaymentHeader>
            <Center>
              <H1>{trans(`title.reservation_title_term_${paymentTerm}`)}</H1>
              <Subtitle>
                {trans(`title.reservation_subtitle_term_${paymentTerm}`)}
              </Subtitle>
            </Center>
          </PaymentHeader>
          <Box
            sx={{ display: "flex", py: 3 }}
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress size={24} thickness={4.5} />
          </Box>
        </Container>
      );
    }
    return (
      <Container>
        {paymentFormData && <AutoSubmitForm {...paymentFormData} />}
        <WarnCancelReservationModal />
        <PaymentHeader>
          <Center>
            <H1>{trans(`title.reservation_title_term_${paymentTerm}`)}</H1>
            <Subtitle>
              {trans(`title.reservation_subtitle_term_${paymentTerm}`)}
            </Subtitle>
          </Center>
        </PaymentHeader>
        <Row>
          <Col lg={8} sm={12}>
            <PaymentMethodSection>
              <Center>
                {error && (
                  <Alert type={AlertType.ERROR}>
                    {trans("error.unknown_payment_error")}
                  </Alert>
                )}
                {paymentTerm !== PAYMENT_TERM_SHORT && (
                  <H3>{`${trans(
                    "title.reservation_number"
                  )} ${reservationNumber}`}</H3>
                )}
                {paymentTerm === PAYMENT_TERM_LONG && (
                  <>
                    <CenterRow>
                      <Col lg={5}>
                        <label>{trans("pricing.pay_total")}</label>
                      </Col>
                      <Col lg={6}>
                        <PayButton
                          onClick={this.handleSelectPaymentAmount}
                          value="full"
                        >
                          <FormattedNumber
                            value={totalPrice}
                            currency="EUR"
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                          />
                          {payType === "full" && <CheckImg src={checkUrl} />}
                        </PayButton>
                      </Col>
                    </CenterRow>
                    <CenterRow>
                      <Col lg={5}>
                        <label>{trans("pricing.pay_partially")}</label>
                      </Col>
                      <Col lg={6}>
                        <PayButton
                          onClick={this.handleSelectPaymentAmount}
                          value="partial"
                          color="neutralDark"
                        >
                          <FormattedNumber
                            value={totalPrice * 0.3}
                            currency="EUR"
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                          />
                          {payType === "partial" && <CheckImg src={checkUrl} />}
                        </PayButton>
                      </Col>
                    </CenterRow>
                  </>
                )}
                {paymentTerm !== PAYMENT_TERM_LONG && (
                  <CenterRow>
                    <Col lg={5}>
                      <label>{trans("pricing.pay_total")}</label>
                    </Col>
                    <Col lg={6}>
                      <PayButton
                        onClick={this.handleSelectPaymentAmount}
                        value="full"
                      >
                        <FormattedNumber
                          value={totalPrice}
                          currency="EUR"
                          minimumFractionDigits={2}
                          maximumFractionDigits={2}
                        />
                      </PayButton>
                    </Col>
                  </CenterRow>
                )}
              </Center>
              <Formik
                initialValues={initialFormValues}
                onSubmit={this.handlePaymentSubmit}
              >
                {({ isSubmitting }) => (
                  <Form>
                    {payType !== null && (
                      <>
                        <Center>
                          <H1>{trans("title.payment_method")}</H1>
                          <P>{trans("title.payment_method_subtitle")}</P>
                        </Center>
                        <Field
                          name="method"
                          nameIssuer="issuer"
                          component={PaymentMethodsField}
                          methods={paymentMethods}
                        />
                        <ButtonGroup>
                          <Button disabled={isSubmitting} type="submit">
                            {trans("button.complete")}
                          </Button>
                        </ButtonGroup>
                      </>
                    )}
                  </Form>
                )}
              </Formik>
            </PaymentMethodSection>
          </Col>
          <Col lg={4} sm={12}>
            <ReservationNotice className="bg-faded-light">
              <ReservationConfirmation
                properties={{ content: confirmationText }}
              />
            </ReservationNotice>
          </Col>
        </Row>
      </Container>
    );
  };
}

PaymentBlock.propTypes = {
  totalPrice: PropTypes.number,
  reservationId: PropTypes.number,
  reservationNumber: PropTypes.string,
  paymentTerm: PropTypes.string,
  language: PropTypes.string,
  country: PropTypes.string,
  city: PropTypes.string,
  resort_id: PropTypes.string,
  email: PropTypes.string,
  onPay: PropTypes.func,
  properties: PropTypes.object,
  router: PropTypes.object,
};

const mapStateToProps = (state) => ({
  reservationId: reservationSelectors.getReservationId(state),
  reservationNumber: reservationSelectors.getReservationNumber(state),
  resort_id: reservationSelectors.getResortId(state),
  paymentTerm: getPaymentTerm(state),
  country: reservationSelectors.getCountry(state),
  city: reservationSelectors.getCity(state),
  email: reservationSelectors.getEmail(state),
  language: reservationSelectors.getLanguage(state),
  totalPrice: reservationSelectors.getTotalPrice(state),
});

const mapDispatchToProps = (dispatch) => ({
  onPay: (data) => dispatch(handlePayment(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PaymentBlock));
